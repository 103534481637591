import React, { useState } from 'react';
import { Card, Checkbox } from 'antd';

const NotePelanggan2 = () => {
  // State to track whether the user has agreed
  const [isAgreed, setIsAgreed] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>
      <Card
        title="Pernyataan Persetujuan (Pelanggan)"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          margin: "0 auto",
          width: "100%",
          maxWidth: "600px", // Maksimal lebar untuk desktop
          marginBottom: "15px",
          textAlign: "left",
          fontSize: '12px',
        }}
      >
        <p>
          Mohon diperhatikan bahwa dengan menghapus akun Gaspoll dan Fello Anda:
        </p>
        <ul>
          <li>
            Anda tidak akan lagi dapat mengakses fitur dan layanan yang ditawarkan melalui platform Gaspoll dan Fello. Oleh karena itu, Anda mungkin ingin menyimpan catatan riwayat transaksi Anda dalam aplikasi Gaspoll dan/atau Fello sebelum melanjutkan proses penghapusan akun.
          </li>
          <li>
            Anda akan kehilangan semua voucher atau saldo yang belum digunakan dalam akun Gaspoll dan Fello Anda, yang tidak dapat dipulihkan, ditransfer ke akun Gaspoll dan Fello lain, dan diuangkan.
          </li>
          <li>
            Jika Anda ingin menggunakan aplikasi Gaspoll dan Fello lagi setelah menghapus akun Anda, Anda akan diminta untuk mendaftar akun baru.
          </li>
          <li>
            Kami berhak menyimpan beberapa informasi dan/atau data pribadi yang terkait dengan akun Gaspoll dan Fello Anda, sepanjang diperlukan oleh hukum yang berlaku.
          </li>
        </ul>
        <p>
          Dengan mengirimkan permintaan ini, Anda mengkonfirmasi bahwa:
        </p>
        <ul>
          <li>Anda adalah pemilik akun yang bersangkutan,</li>
          <li>Anda telah membaca dan memahami konsekuensi penghapusan akun Gaspoll dan Fello sebagaimana telah dijelaskan di atas, dan</li>
          <li>Anda setuju untuk memberikan izin kepada kami untuk menghapus akun Gaspoll dan Fello Anda.</li>
        </ul>
        <p>
          Kami berhak untuk menolak permintaan penghapusan akun Anda jika diizinkan atau diperlukan berdasarkan hukum yang berlaku. Ini termasuk situasi di mana kami menganggap permintaan tersebut tidak relevan, tidak serius atau mengada-ada, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan yang melawan hukum.
        </p>
        <p>
          Email konfirmasi akan dikirimkan setelah akun Gaspoll dan Fello Anda berhasil dihapus.
        </p>

        {/* Checkbox for consent */}
        <div style={{ marginBottom: '10px' }}>
          <Checkbox
            checked={isAgreed}
            onChange={handleCheckboxChange}
          >
            Setuju? Silakan setujui untuk melanjutkan.
          </Checkbox>
        </div>

        {/* Conditionally render text */}
        {!isAgreed && (
          <p style={{ color: 'red' }}>
            Silakan setujui untuk melanjutkan.
          </p>
        )}
      </Card>
    </div>
  );
};

export default NotePelanggan2;
