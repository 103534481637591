import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Input, Button, Select, Card, Row, Col, notification, Spin, Modal } from "antd";
import { Link } from "react-router-dom";
import DesapediaLogo from "../../../assets/images/Desapedia-01.png";
import { config } from "../../../config";
import NoteMitraUsaha from "../Note/MitraUsaha/NoteMitraUsaha";
import NotePengemudi from "../Note/Pengemudi/NodePengemudi";
import NotePengemudi2 from "../Note/Pengemudi/NodePengemudi2";
import NotePelanggan from "../Note/Pelanggan/NotePelanggan";
import NotePelanggan2 from "../Note/Pelanggan/NotePelanggan2";
import { useForm } from "antd/es/form/Form";

const { Option } = Select;

const PrivacyWebForm = () => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [userType, setUserType] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [requestSubType, setRequestSubType] = useState(null);
  const [deleteAccount, setDeleteAccunt] = useState(null);
  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [formOptions, setFormOptions] = useState({
    userTypeOptions: [],
    requestTypeOptions: [],
    requestSubTypeOptions: [],
    deleteRequestOptions: [],
    deleteReasonOptions: [],
    deleteConfirmOptions: [],
  });
  const [kodeReason, setKodeReason ] = useState (null)
  const [kodePelanggan, setKodePelanggan] = useState (null)
  const [kodePengguna, setKodePengguna] = useState (null)
  const [kodePengemudi, setKodePengemudi] = useState (null)
  const [kodeConfirm, setKodeConfirm] = useState (null)
  const [loadingData, setLoadingData] = useState (false)

  const [typePengguna, setTypePengguna] = useState([])
  const [typeRequest, setTypeRequest] = useState([])
  const [typeSubRequest, setTypeSubRequest] = useState([])
  const [typeAccount, setTypeDeleteAccount] = useState([])
  const [typeReason, setTypeReason] = useState([])
  const [typeConfirm, setTypeConfirm] = useState([])

  const onChangeTypePengguna = (value, index) => {
    if (value) {
    form.setFieldsValue ({
      requestType: null,
      requestSubType: null,
      deleteAccount: null,
      deleteReason: null,
      deleteConfirm: null,
    })
    setRequestType(null);
    setRequestSubType(null);
    setDeleteAccunt(null);
    setDeleteReason(null);
    setDeleteConfirm(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
    setKodeReason(null);
    
  
    setUserType(value);
    setKodePengguna(index.key);

    // console.log('Kode Pengguna :', index.key);
    
  
    const newData = formOptions.deleteRequestOptions.filter(
      (data) => data.mtipePenggunaId === value
    );
    setTypeDeleteAccount(newData)
  }
  };

  const onChangeTypePermintaan = (value) => {
    if (value) {
    form.setFieldsValue ({
      requestSubType: null,
      deleteAccount: null,
      deleteReason: null,
      deleteConfirm: null,
    })
    
      setRequestSubType(null);
      setDeleteAccunt(null);
      setDeleteReason(null);
      setDeleteConfirm(null);
      setKodePelanggan(null);
      setKodePengemudi(null);
  
    setRequestType(value);  
  
    const newData = formOptions.requestSubTypeOptions.filter(
      (data) => data.mtipePengajuanId === value
    );
    setTypeSubRequest(newData)
  }
  };

  const onChangeTypeSubPermintaan = (value) => {
    if (value) {
    form.setFieldsValue ({
      deleteAccount: null,
      deleteReason: null,
      deleteConfirm: null,
    })
    setKodePelanggan(null);
    setKodePengemudi(null);

    setRequestSubType(value);
  }
  };

  const onChangeDeleteAccount = (value) => {
    if (value) {
    form.setFieldsValue ({
      deleteReason: null,
      deleteConfirm: null,
    })

      setDeleteReason(null);
      setDeleteConfirm(null);
      setKodePelanggan(null);
      setKodePengemudi(null);
  
    setDeleteAccunt(value);  
  
    const newData = formOptions.deleteReasonOptions.filter(
      (data) => data.mjenisAkunId === value && 
                data.mtipePengajuanId === requestType
    );
    setTypeReason(newData)
  }
  };

  const onChangeDeleteReason = (value, index) => {
      // console.log('Vlaue', value);
    if (value) {
      form.setFieldsValue ({
        deleteConfirm: null,
      })
      
        setDeleteConfirm(null);
        setKodePengemudi(null);
        setKodePelanggan(null);
    
      setDeleteReason(value);
      setKodeReason(index.key);
    
      const newData = formOptions.deleteConfirmOptions.filter(
        (data) =>
          data.mtipePengajuanId === requestType
      );
      setTypeConfirm(newData)
      // console.log('Kode Tes:',index.key);
    }
  };
  
  const onChangeDeleteAccess = (value, index) => {
    if (value) {
      setDeleteConfirm(null);
      setKodePelanggan(null);
      setKodePengemudi(null);
      setKodeConfirm(null);
  
      setDeleteConfirm(value);
      setKodePelanggan(index.key);
      setKodePengemudi(index.key);
      setKodeConfirm(index.key);
    }
    // console.log('Kodeee',index.key);
    
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}pengajuanAkun/referensi`
        );
        const data = response.data.data;
        setFormOptions({
          userTypeOptions: data.tipePengguna,
          requestTypeOptions: data.tipePengajuan,
          requestSubTypeOptions: data.jenisSubPengajuan,
          deleteRequestOptions: data.jenisAkun,
          deleteReasonOptions: data.reasonPengajuan,
          deleteConfirmOptions: data.reasonAksesAkun,
        });
        setTypePengguna(data.tipePengguna)
        setTypeRequest(data.tipePengajuan)
        setTypeSubRequest(data.jenisSubPengajuan)
        setTypeDeleteAccount(data.jenisAkun)
        setTypeReason(data.reasonPengajuan)
        setTypeConfirm(data.reasonAksesAkun)
      } catch (error) {
        console.error("Error fetching data from API:", error);
      } finally {
        setLoadingData(false);  // Set loading to false once the data has been fetched
      }
    };
  
    fetchData();
  }, []);  

  const handleFormSubmit = async (values) => {
    setLoading(true);
    setModalVisible(true); // Tampilkan overlay
    try {
      const payload = {
        createdBy: values.accountName,
        email: values.email,
        isactive: "Y",
        isupgrade: "N",
        keterangan: values.requestDetails,
        mjenisAkunId: deleteAccount,  // Use the actual state value
        mjenisSubPengajuanId: requestSubType, // Use actual state value
        mreasonAksesAkunId: deleteConfirm,  // Use actual state value
        mreasonPengajuanId: deleteReason,  // Use actual state value
        mtipePengajuanId: requestType,  // Use actual state value
        mtipePenggunaId: userType,  // Use actual state value
        nama: values.accountName,
        nomorHp: values.nomor,
        pengajuanAkunId: "12345",  // Ensure this ID is correct or dynamically generated
        updatedBy: values.accountName,
      };      

      const response = await axios.post(`${config.BASE_URL}pengajuanAkun/create`, payload);

      console.log("Form submitted successfully:", response.data);
      notification.success({
        message: "Pengajuan Berhasil",
        description: "Formulir telah berhasil dikirim.",
      });

      // RESET FORM
      form.resetFields();  // <=== Tambahkan ini
      setUserType(null);
      setRequestType(null);
      setRequestSubType(null);
      setDeleteAccunt(null);
      setDeleteReason(null);
      setDeleteConfirm(null);
      setKodeReason(null);
      setKodePelanggan(null);
      setKodePengguna(null);
      setKodePengemudi(null);
      setModalVisible(false); // Sembunyikan overlay setelah sukses
    } catch (error) {
      console.error("Error submitting form:", error.response || error.message);
      notification.error({
        message: "Pengajuan Gagal",
        description: error.response?.data?.message || "Terjadi kesalahan saat mengirim formulir.",
      });
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };
  
  const handleClearRequestType = () => {
    setRequestType(null);
    setRequestSubType(null);
    setDeleteAccunt(null);
    setDeleteReason(null);
    setDeleteConfirm(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
    setTypeDeleteAccount(formOptions.deleteReasonOptions)
    setTypeReason(formOptions.deleteReasonOptions)
    setTypeConfirm(formOptions.deleteConfirmOptions)
  };
  
  const handleClearRequestSubType = () => {
    setRequestSubType(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
  };
  
  const handleClearDeleteAccount = () => {
    setDeleteAccunt(null);
    setDeleteReason(null);
    setDeleteConfirm(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
    setTypeReason(formOptions.deleteReasonOptions)
    setTypeConfirm(formOptions.deleteConfirmOptions)
  };
  
  const handleClearDeleteReason = () => {
    setDeleteReason(null);
    setKodeReason(null);
    setDeleteConfirm(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
    setTypeConfirm(formOptions.deleteConfirmOptions)
  };
  
  const handleClearDeleteConfirm = () => {
    setDeleteConfirm(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
    setKodePelanggan(null);
    setKodePengemudi(null);
  };

  return (
    <>
      <header className="privacy-header">
        <Row>
          <Col span={4}>
            <Link to="/">
              <img src={DesapediaLogo} alt="Desapedia Logo" height="35" />
            </Link>
          </Col>
        </Row>
      </header>

      <section className="privacy-intro">
        <Row className="justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-5">
              <h1>Formulir privasi</h1>
              <p>
                Desapedia memahami bahwa pengalaman pelanggan yang lebih baik
                dimulai dengan privasi dan perlindungan data. Kami senang dapat
                membantu setiap pertanyaan, masukan, atau permintaan yang Anda
                miliki mengenai Pemberitahuan Privasi Desapedia, praktik privasi
                dan perlindungan data kami, atau bagaimana Desapedia menerapkan
                hukum privasi dan perlindungan data.
              </p>
            </div>
          </div>
        </Row>
      </section>

      {/* MODAL OVERLAY LOADING */}
      <Modal open={modalVisible} footer={null} closable={false} centered>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <p style={{ marginTop: "10px" }}>Proses pengajuan formulir...</p>
        </div>
      </Modal>
        
      <section className="privacy-form">
        <Row justify="center">
          <Col lg={15}>
            <Card
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "20px",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                margin: "0 auto",
                maxWidth: "100%",
              }}
            >
              <Spin spinning={loadingData}>
              <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                <Form.Item
                  label="Pilih tipe pengguna"
                  name="userType"
                  required
                >
                  <Select
                    placeholder="Pilih tipe pengguna"
                    onChange={onChangeTypePengguna}
                    // value={userType}
                    allowClear
                    // onClear={handleClearUserType}
                    className="rounded-input"
                  >
                    {typePengguna.map((option) => (
                      <Option key={`${option.kode}`} value={option.mtipePenggunaId}>
                        {option.nama}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {userType && (
                  <Form.Item
                    label="Pilih tipe pengajuan"
                    name="requestType"
                    required
                  >
                    <Select
                      placeholder="Pilih tipe pengajuan"
                      onChange={onChangeTypePermintaan}
                      // value={requestType}
                      allowClear
                      onClear={handleClearRequestType}
                      className="rounded-input"
                    >
                      {typeRequest.map((option) => (
                        <Option key={`${option.mtipePengajuanId}`} value={option.mtipePengajuanId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {requestType && (
                  <Form.Item
                    label="Pilih tipe sub permintaan"
                    name="requestSubType"
                    required
                  >
                    <Select
                      placeholder="Pilih tipe sub permintaan"
                      onChange={onChangeTypeSubPermintaan}
                      // value={requestSubType}
                      allowClear
                      onClear={handleClearRequestSubType}
                      className="rounded-input"
                    >
                      {typeSubRequest.map((option) => (
                        <Option key={`${option.mjenisSubPengajuanId}`} value={option.mjenisSubPengajuanId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {requestSubType && (
                  <Form.Item
                    label="Pilih akun yang ingin dihapus"
                    name="deleteAccount"
                    required
                  >
                    <Select
                      placeholder="Pilih akun yang ingin dihapus"
                      onChange={onChangeDeleteAccount}
                      // value={deleteAccount}
                      allowClear
                      onClear={handleClearDeleteAccount}
                      className="rounded-input"
                    >
                      {typeAccount.map((option) => (
                        <Option key={`${option.mjenisAkunId}`} value={option.mjenisAkunId}>
                          {option.nama}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {deleteAccount && (
                  <Form.Item
                    label="Pilih alasan penghapusan akun"
                    name="deleteReason"
                    required
                  >
                    <Select
                      placeholder="Pilih alasan penghapusan akun"
                      onChange={onChangeDeleteReason}
                      // value={deleteReason}
                      allowClear
                      onClear={handleClearDeleteReason}
                      className="rounded-input"
                    >
                      {typeReason.map((option) => (
                        <Option
                          key={`${option.kode}`}
                          value={option.mreasonPengajuanId}
                        >
                          {option.isi}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {/* {!kodeReason && deleteReason && ( */}
                {(kodeReason === "004" || kodeReason === "005" || kodeReason === "006" || kodeReason === "007") && (
                  <NoteMitraUsaha />
                )}
                
                {/* {kodeReason === "004" ? (
                  <NoteMitraUsaha />
                ) : ( */}
                {!(kodeReason === "004" || kodeReason === "005" || kodeReason === "006" || kodeReason === "007") && deleteReason && (
                  <Form.Item
                    label="Apakah Anda masih memiliki akses ke akun yang ingin Anda hapus?"
                    name="deleteConfirm"
                    required
                  >
                    <Select
                      placeholder="Apakah Anda masih memiliki akses ke akun?"
                      onChange={onChangeDeleteAccess}
                      // value={deleteConfirm}
                      allowClear
                      onClear={handleClearDeleteConfirm}
                      className="rounded-input"
                    >
                      {typeConfirm.map((option) => (
                        <Option
                          key={`${option.kode}`}
                          value={option.mreasonAksesAkunId}
                        >
                          {option.isi}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {/* Tampilkan NotePengemudi jika kodePelanggan adalah '001' dan kodePengguna adalah 'MITRA PENGEMUDI' */}
                {kodePelanggan === '001' && kodePengguna === 'MITRA PENGEMUDI' && <NotePengemudi />}
                              
                {/* Tampilkan NotePengemudi2 jika kodePelanggan adalah '002' dan kodePengguna adalah 'MITRA PENGEMUDI' */}
                {kodePelanggan === '002' && kodePengguna === 'MITRA PENGEMUDI' && <NotePengemudi2 />}
                              
                {/* Tampilkan NotePelanggan jika kodePelanggan adalah '001' dan kodePengguna adalah 'PELANGGAN' */}
                {kodePelanggan === '001' && kodePengguna === 'PELANGGAN' && <NotePelanggan />}
                              
                {/* Tampilkan NotePelanggan2 jika kodePelanggan adalah '002' dan kodePengguna adalah 'PELANGGAN' */}
                {kodePelanggan === '002' && kodePengguna === 'PELANGGAN' && <NotePelanggan2 />}
                              
                {/* Form akan muncul secara default kecuali jika kondisi tertentu terpenuhi */}
                {!(
                  (kodePelanggan === '001' && kodePengguna === 'MITRA PENGEMUDI') ||
                  (kodePelanggan === '001' && kodePengguna === 'PELANGGAN')
                ) && (
                  <>
                    <Form.Item label="Detail Permintaan" name="requestDetails" required>
                      <Input.TextArea rows={4} placeholder="Detail Permintaan" />
                    </Form.Item>
                
                    <Form.Item
                      label="Nama Pengguna"
                      name="accountName"
                      rules={[{ required: true, message: "Harap isi nama pengguna!" }]}
                    >
                      <Input.TextArea placeholder="Nama Pengguna" style={{ borderRadius: "20px" }} />
                    </Form.Item>
                
                    <Form.Item
                      label="Nomor yang terdaftar"
                      name="nomor"
                      rules={[{ required: true, message: "Harap isi nomor yang terdaftar!" }]}
                    >
                      <Input.TextArea placeholder="Nomor yang terdaftar" style={{ borderRadius: "20px" }} />
                    </Form.Item>
                
                    <Form.Item
                      label="Email terdaftar"
                      name="email"
                      rules={[{ required: true, message: "Harap isi email terdaftar!" }]}
                    >
                      <Input.TextArea placeholder="Email terdaftar" style={{ borderRadius: "20px" }} />
                    </Form.Item>
                
                    <Form.Item>
                      <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#F2643E",
                          borderColor: "#F2643E",
                          padding: "10px 30px",
                          fontSize: "15px",
                        }}
                      >
                        Kirim
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form>
              </Spin>
            </Card>
          </Col>
        </Row>
      </section>
              
      <footer className="privacy-footer">
        <Row justify="center">
          <Col span={24} className="text-center">
            <p>
              &copy; 2025 Desapedia | Desapedia adalah merek dagang dari PT
              Agriva Teknologi Nusantara. Terdaftar di Direktorat Jenderal
              Kekayaan Intelektual Republik Indonesia.
            </p>
          </Col>
        </Row>
      </footer>
    </>
  );
};

export default PrivacyWebForm;
