import React from 'react';
import { Typography, List } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div style={{
      padding: '40px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px', // Ukuran font standar
      lineHeight: '1.6', // Jarak antar baris
      margin: '0 auto',
      maxWidth: '800px', // Lebar maksimum untuk konten
    }}>
      <Title level={1} style={{ fontSize: '24px', textAlign: 'center', fontWeight: 'bold' }}>KEBIJAKAN PRIVASI DESAPEDIA</Title>
      <Title level={2} style={{ fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>PEMBERITAHUAN PRIVASI</Title>
      <Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>Berlaku mulai dari 20 Januari 2025</Paragraph>

      <Paragraph level={2} style={{ fontSize: '15px', fontWeight: 'bold' }}>PENGANTAR</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Pemberitahuan Privasi ini mulai berlaku sejak tanggal 20 Januari 2025.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Pemberitahuan Privasi ini menjelaskan bagaimana Kami, PT Agriva Teknologi Nusantara beserta afiliasinya (<strong>"Desapedia"</strong>) mengumpulkan, memperoleh, menyimpan, mengelola, memanfaatkan, memproses, menganalisis, memperbarui, menampilkan, mengungkapkan, mentransfer, serta melindungi Data Pribadi Anda (<strong>"Pemrosesan Data Pribadi"</strong>). Pemberitahuan Privasi ini berlaku untuk seluruh pengguna dan/atau penyedia layanan (<strong>"Pengguna" atau "Anda"</strong>) yang mengakses aplikasi seluler Kami (termasuk aplikasi Gaspol Driver), situs web (termasuk www.desapedia.co.id dan situs terkait lainnya), fitur, layanan, serta produk Kami (secara kolektif disebut <strong>"Aplikasi"</strong>), kecuali jika terdapat pemberitahuan privasi yang terpisah yang mengatur.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami menyarankan Anda untuk membaca Pemberitahuan Privasi ini dengan seksama agar Anda dapat memahami bagaimana Kami melindungi data Anda. Untuk kemudahan, kami telah menyertakan ringkasan poin-poin utama di bagian awal. Istilah-istilah yang digunakan dengan huruf kapital dalam Pemberitahuan Privasi ini memiliki arti yang sama dengan yang tertera dalam Ketentuan Penggunaan yang berlaku antara Anda dan Desapedia.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>PENGAKUAN DAN PERSETUJUAN</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Dengan menyetujui Pemberitahuan Privasi ini, Anda menyatakan bahwa Anda telah membaca, memahami, dan setuju dengan semua ketentuan yang ada. Secara khusus, Anda memberikan persetujuan kepada Kami untuk memproses Data Pribadi Anda sesuai dengan ketentuan dalam Pemberitahuan Privasi ini.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Jika Anda memberikan Data Pribadi yang berkaitan dengan orang lain (seperti pasangan, anggota keluarga, teman, atau pihak lain), Anda menjamin bahwa Anda telah memperoleh izin dari individu tersebut untuk memproses Data Pribadi mereka. Anda juga menyetujui pemrosesan data tersebut atas nama individu yang dimaksud. Kami berhak meminta bukti persetujuan tersebut dari Anda kapan saja.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Jika Anda menerima Data Pribadi Pengguna lain (termasuk Data Pribadi mitra Kami) saat menggunakan layanan di Aplikasi Kami, Anda mengerti dan memastikan bahwa Data Pribadi tersebut hanya akan digunakan untuk tujuan penggunaan layanan tersebut. Anda sepenuhnya bertanggung jawab atas segala bentuk penyalahgunaan Data Pribadi yang mungkin terjadi. Selama tidak bertentangan dengan hukum yang berlaku, Anda membebaskan Kami dari segala tanggung jawab terkait dengan penyalahgunaan Data Pribadi yang Anda lakukan.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Anda berhak untuk mencabut persetujuan atas Pemrosesan Data Pribadi Anda kapan saja dengan memberikan pemberitahuan tertulis yang wajar kepada Kami menggunakan kontak yang tercantum dalam Pemberitahuan Privasi ini. Anda juga dapat menarik persetujuan untuk menerima komunikasi tertentu melalui fitur "Hapus Akun" yang ada di aplikasi Desapedia, atau dengan menggunakan opsi yang tersedia di setiap saluran komunikasi yang Kami gunakan untuk menghubungi Anda. Anda perlu memahami bahwa setelah mencabut persetujuan, Anda mungkin tidak dapat lagi mengakses Aplikasi atau layanan Kami. Penarikan persetujuan dapat menyebabkan penghentian layanan, penghapusan akun, atau berakhirnya hubungan kontraktual Anda dengan Kami, namun semua hak dan kewajiban yang ada tetap harus dipenuhi. Setelah menerima permintaan penarikan persetujuan, Kami akan memberitahukan Anda tentang dampak yang mungkin timbul, sehingga Anda dapat mempertimbangkan kembali keputusan untuk mencabut persetujuan tersebut.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Data apa yang Kami kumpulkan tentang Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami mengumpulkan Data Pribadi ketika Anda menggunakan Aplikasi Kami, seperti data identitas NIK (Nomor Induk Kependudukan), informasi kontak, data kelayakan, transaksi, keuangan, pembayaran, catatan (log), dan/atau data lokasi. Selain itu, Kami juga mengumpulkan data terkait perangkat dan teknis, serta informasi lain yang mungkin Anda berikan atau kirimkan saat menggunakan Aplikasi Kami. Jika Anda memutuskan untuk tidak memberikan data tersebut, Kami mungkin tidak dapat memberikan layanan dan pengalaman Aplikasi yang optimal.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Bagaimana Kami menggunakan data tentang Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami menggunakan Data Pribadi Anda untuk mengelola dan mengatur akun Anda dengan Kami, berkomunikasi dengan Anda, serta menyediakan berbagai fitur, layanan, dan fungsi dalam Aplikasi Kami.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Jika Anda merupakan penyedia layanan, Kami menggunakan data Anda untuk memverifikasi kelayakan Anda dalam menyediakan layanan kepada pengguna, mengelola akun Anda dalam Aplikasi Kami, berkomunikasi dengan Anda, serta memberikan berbagai layanan dan fungsi yang tersedia di Aplikasi Kami.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami juga memanfaatkan data Anda untuk pemeliharaan Aplikasi Kami dan untuk menyesuaikan produk serta layanan Kami dengan preferensi Anda. Selain itu, data Anda digunakan untuk memasarkan produk dan layanan Kami, serta dari perusahaan afiliasi Kami, mitra, dan agen (dengan persetujuan Anda jika diperlukan oleh peraturan yang berlaku).
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Dengan siapa Kami membagikan data Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami membagikan Data Pribadi Anda dengan mitra pihak ketiga Kami untuk mendukung pelaksanaan layanan yang disediakan untuk atau oleh Anda, sejauh yang diperlukan agar mereka dapat memberikan layanan mereka. Hal ini mencakup, namun tidak terbatas pada, pengelolaan administrasi surat menyurat desa, pemrosesan pembayaran, pengiriman barang, penyediaan produk, serta klaim dan verifikasi asuransi.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Dimana Kami memproses data Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami memproses Data Pribadi Anda di berbagai lokasi, baik di Indonesia maupun di luar negeri. Selain itu, Kami dapat mentransfer data Anda kepada afiliasi Kami dan mitra pihak ketiga di luar Indonesia untuk tujuan yang dijelaskan dalam Pemberitahuan Privasi ini.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Berapa lama Kami memproses data Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami memproses Data Pribadi Anda selama Anda memberikan persetujuan dan sejauh diperlukan untuk mencapai tujuan pengumpulan dan perolehannya, atau sesuai dengan kewajiban yang diatur oleh peraturan perundang-undangan yang berlaku.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Bagaimana cara Anda dapat melaksanakan hak Anda atas Data Pribadi Anda?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Anda mungkin memiliki berbagai hak terkait Data Pribadi Anda sesuai dengan peraturan perundang-undangan yang berlaku. Jika Anda ingin mengajukan permohonan untuk menggunakan hak Anda, Anda dapat menghubungi Kami melalui kontak yang tercantum dalam Pemberitahuan Privasi ini. Untuk permohonan penghapusan akun, Anda dapat melakukannya melalui situs web yang telah disediakan dalam Pemberitahuan Privasi ini.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Hubungi Kami</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Jika Anda memiliki pertanyaan, kekhawatiran, atau keluhan, Anda dapat menghubungi Kami melalui informasi kontak yang tersedia pada bagian Cara Menghubungi Kami.
      </Paragraph>

      <Paragraph level={2} style={{ fontSize: '15', fontWeight: 'bold' }}>Bagaimana Kami akan memberitahu Anda terkait perubahan Pemberitahuan Privasi ini?</Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        Kami dapat memperbarui Pemberitahuan Privasi ini dari waktu ke waktu dan akan memberitahukan Anda tentang versi yang diperbarui melalui saluran yang sesuai, seperti Aplikasi Kami atau email yang terdaftar pada Kami. Harap periksa secara berkala untuk mengetahui pembaruan atau perubahan apapun pada Pemberitahuan Privasi ini.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', fontWeight: 'bold' }}>Penjelasan lebih lanjut tentang hal di atas dapat dibaca pada bagian di bawah ini.</Title>
      <Title level={2}style={{ fontSize: '18px', textDecoration: 'underline' }}>1. JENIS-JENIS DATA PRIBADI YANG KAMI KUMPULKAN TENTANG ANDA</Title>
      <List
        style={{textAlign: 'justify'}}
        dataSource={[
          "1. Data Identitas mencakup nama, nama pengguna (username), nomor identitas seperti KTP, NPWP, atau identifikasi lainnya, jabatan, tanggal lahir, jenis kelamin, tempat kelahiran, pekerjaan, kewarganegaraan, foto, dan/atau data biometrik.",
          "2. Data Kontak mencakup alamat penagihan, alamat pengiriman dan labelnya, alamat email, nomor telepon, serta daftar kontak.",
          "3. Data Kelayakan meliputi informasi terkait izin, persetujuan, dan otorisasi yang memungkinkan Anda untuk mengoperasikan kendaraan atau menyediakan layanan transportasi lainnya, serta rincian mengenai polis asuransi.",
          "4. Data Transaksi mencakup informasi terkait pembelian atau pemesanan yang Anda lakukan, minat dan preferensi, masukan atau tanggapan dari survei, serta rincian mengenai pengambilan dan/atau pengiriman dan jenis layanan yang tersedia pada waktu tersebut.",
          "5. Data Keuangan mencakup informasi terkait rekening bank dan kartu pembayaran, seperti jenis kartu atau rekening yang digunakan, nama penerbit kartu atau rekening, nama pemegang kartu atau rekening, nomor identifikasi rekening atau kartu, kode verifikasi kartu atau akun, tanggal kedaluwarsa kartu atau akun (jika berlaku), data virtual account, riwayat transaksi keuangan (termasuk riwayat transaksi kartu atau rekening, rincian kartu atau rekening, kode CVV, status kartu atau rekening), serta data penilaian kredit (credit scoring).",
          "6. Data Pembayaran mencakup rincian pembayaran atau transfer yang dilakukan oleh Pengguna di Aplikasi Kami, termasuk informasi terkait pembayaran, penerima pembayaran (termasuk rincian rekening mereka), metode pembayaran yang digunakan, jumlah pembayaran, rincian tagihan, dan informasi faktur.",
          "7. Data Teknis mencakup informasi tentang penggunaan Aplikasi Kami, seperti ID pengguna yang dihasilkan oleh Aplikasi, layanan pesan dalam aplikasi, layanan pencarian dalam aplikasi, alamat IP, halaman web yang dilihat sebelum atau setelahnya, durasi kunjungan atau sesi, ID perangkat internet atau alamat kontrol akses media, ID periklanan, serta informasi perangkat lainnya, termasuk informasi mengenai produsen, model, dan sistem operasi perangkat yang digunakan untuk mengakses Aplikasi, serta crash logs.",
          "8. Data Perangkat mencakup jenis perangkat yang digunakan untuk mengakses Aplikasi, termasuk model perangkat keras, sistem operasi dan versinya, perangkat lunak, nama dan versi file, pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri, informasi gerakan perangkat, dan/atau informasi jaringan seluler.",
          "9. Data Catatan (Log) mencakup catatan yang disimpan pada server terkait data yang diterima, seperti alamat IP perangkat, tanggal dan waktu akses, fitur Aplikasi atau halaman yang dilihat, proses kerja Aplikasi dan aktivitas sistem lainnya, jenis peramban yang digunakan, serta situs atau layanan pihak ketiga yang diakses sebelum berinteraksi dengan Aplikasi.",
          "10. Data Lokasi mencakup informasi mengenai lokasi geografis Anda secara waktu-nyata (real-time).",
          "11. Data Aplikasi yang Terinstal berlaku apabila Anda menggunakan aplikasi Desapedia (di perangkat Android & iOS) dan aplikasi GaspolDriver (di perangkat Android), yang merujuk pada informasi mengenai aplikasi yang terinstal di perangkat Anda. Ini termasuk, namun tidak terbatas pada, aplikasi yang menurut kebijakan Kami, dan sepanjang tidak bertentangan dengan peraturan yang berlaku, dapat menimbulkan risiko terkait transaksi yang mencurigakan atau aktivitas yang dilarang, ilegal, tidak sah, atau curang. Jumlah informasi yang Kami kumpulkan dapat berubah seiring waktu."
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>2. JIKA ANDA MENYEDIAKAN DATA PRIBADI YANG TIDAK LENGKAP</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
        Jika Kami diwajibkan untuk mengumpulkan Data Pribadi berdasarkan hukum atau ketentuan perjanjian yang berlaku antara Kami dan Anda, dan Anda memilih untuk tidak memberikan Data Pribadi tersebut atau memberikan data yang tidak lengkap saat diminta, Kami mungkin tidak dapat menyediakan layanan atau melaksanakan perjanjian yang telah atau sedang disepakati dengan Anda.
      </Paragraph>
      
      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>3. BAGAIMANA KAMI MENGUMPULKAN DATA PRIBADI ANDA</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
        Data Pribadi yang Kami kumpulkan dapat diberikan oleh Anda secara langsung atau diperoleh dari pihak ketiga (misalnya, saat Anda mendaftar atau menggunakan Aplikasi, menghubungi layanan pelanggan Kami, atau ketika Anda menyediakan Data Pribadi kepada Kami). Kami dapat mengumpulkan data dalam berbagai bentuk dan untuk berbagai tujuan, termasuk tujuan yang diizinkan berdasarkan Peraturan Perundang-undangan yang Berlaku.
      </Paragraph>
      <List
        style={{textAlign: 'justify'}}
        dataSource={[
          "a. Data yang Kami kumpulkan dari Anda secara langsung",
          "1. Anda dapat memberikan kepada Kami Data Identitas, Data Kontak, Data Transaksi, Data Kelayakan, Data Keuangan, dan Data Pembayaran, baik saat diminta oleh Kami maupun ketika diwajibkan oleh Peraturan Perundang-undangan yang Berlaku, ketika berinteraksi langsung dengan Kami atau berkorespondensi melalui surat, email, atau saluran komunikasi lainnya. Ini termasuk Data Pribadi yang Anda sediakan ketika Anda:",
          "2. Mendaftar dan membuat akun melalui Aplikasi.",
          "3. Menggunakan Aplikasi, termasuk jika Anda mendaftar sebagai penyedia layanan atau menggunakan layanan sebagai pengguna.",
          "4. Melakukan pembayaran menggunakan metode yang tersedia, termasuk tetapi tidak terbatas pada pembayaran melalui kartu kredit, virtual account, transfer bank, fasilitas uang elektronik, dan/atau dompet elektronik (baik sebagai pembayar maupun penerima).",
          "5. Mendaftar sebagai pemilik akun terdaftar atau terverifikasi untuk fasilitas uang elektronik.",
          "6. Menambahkan sumber pendanaan untuk pembayaran di Aplikasi.",
          "7. Menggunakan fitur chat dalam Aplikasi.",
          "8. Memberikan masukan atau menghubungi Kami.",

          "b. Data yang dikumpulkan setiap kali Anda menggunakan Aplikasi",
          "1. Anda dapat memberikan kepada Kami Data Teknis, Data Perangkat, Data Catatan, dan/atau Data Lokasi setiap kali Anda menggunakan atau mengunjungi Aplikasi. Kami juga dapat secara berkala mengumpulkan, memproses, membagikan, dan menyimpan Data Aplikasi yang Terinstal. Setiap kali Anda mengakses Aplikasi melalui perangkat seluler, Kami akan melacak dan mengumpulkan data lokasi geografis Anda secara waktu-nyata (real-time). Dalam beberapa kasus, Anda akan diminta untuk mengaktifkan Global Positioning System (GPS) pada perangkat seluler Anda agar Kami dapat memberikan pengalaman yang lebih baik saat menggunakan Aplikasi (misalnya, untuk memberi Anda informasi mengenai jarak penyedia layanan dari Anda). Anda selalu dapat memilih untuk menonaktifkan pelacakan lokasi geografis pada perangkat Anda, meskipun hal ini dapat memengaruhi fungsi yang tersedia di Aplikasi.",
          "2. Jika Anda menggunakan fasilitas uang elektronik dan/atau dompet elektronik untuk melakukan pembayaran atau transfer melalui Aplikasi Kami (baik sebagai pembayar atau penerima), Kami dapat mengumpulkan Data Keuangan dan Data Pembayaran yang terkait dengan transaksi tersebut.",
          "3. Selain itu, setiap kali Anda menggunakan atau mengunjungi Aplikasi, informasi tertentu dapat dikumpulkan secara otomatis menggunakan cookies. Cookies adalah file kecil yang disimpan pada perangkat Anda, baik itu komputer atau perangkat seluler. Kami menggunakan cookies untuk melacak aktivitas Anda demi meningkatkan antarmuka dan pengalaman pengguna. Sebagian besar perangkat seluler dan browser internet mendukung penggunaan cookies, namun Anda dapat menyesuaikan pengaturan perangkat atau browser Anda untuk menolak beberapa jenis cookies atau cookies tertentu. Anda juga dapat menghapus cookies yang telah disimpan kapan saja melalui pengaturan perangkat atau browser Anda, meskipun hal tersebut dapat memengaruhi fungsi-fungsi tertentu di Aplikasi.",
          "4. Kami juga mungkin menggunakan fitur-fitur dari pihak ketiga untuk meningkatkan layanan dan konten Kami, termasuk untuk penilaian, penyesuaian, dan penyajian penawaran kepada Anda berdasarkan minat atau riwayat kunjungan Anda. Jika Anda tidak ingin menerima penawaran yang disesuaikan berdasarkan informasi tersebut, Anda dapat mengubah pengaturannya melalui browser Anda.",

          "c. Data yang dikumpulkan dari pihak ketiga",
          "1. Kami juga dapat mengumpulkan Data Pribadi Anda dari pihak ketiga, termasuk agen, vendor, pemasok, kontraktor, mitra, institusi pemerintah, dan pihak lain yang menyediakan layanan untuk Kami, mengumpulkan Data Pribadi, atau menjalankan tugas atas nama Kami, atau yang bekerja sama dengan Kami. Dalam hal ini, Kami hanya akan mengumpulkan Data Pribadi Anda untuk tujuan yang terkait dengan keterlibatan pihak ketiga tersebut atau tujuan kerja sama Kami dengan pihak ketiga tersebut (tergantung pada keadaan). Secara khusus, ketika Anda mendaftarkan kartu pembayaran atau rekening melalui Aplikasi, serta saat Anda mengakses, menambahkan, atau menghubungkan kartu pembayaran atau rekening Anda pada Aplikasi (tergantung pada negara tempat Anda berada), Kami dapat mengumpulkan informasi keuangan tertentu dan catatan finansial Anda, termasuk namun tidak terbatas pada catatan transaksi, rincian kartu pembayaran atau rekening, serta status dan negara dari kartu pembayaran atau rekening Anda, yang diperoleh dari penerbit kredensial pembayaran atau pihak ketiga lainnya.",

          "d. Data tentang pihak ketiga yang Anda berikan kepada Kami",
          "1. Anda dapat memberikan kepada Kami Data Pribadi yang berkaitan dengan individu lain (termasuk Data Pribadi yang terkait dengan pasangan, anggota keluarga, teman, atau orang lain). Dalam hal ini, Anda tentunya perlu mendapatkan persetujuan dari individu tersebut — silakan merujuk pada bagian “Pengakuan dan Persetujuan” di atas untuk informasi lebih lanjut.",
          "2. Jika Anda menggunakan fitur pesan di Aplikasi perangkat seluler Kami, Anda akan memberikan nomor telepon yang tersimpan dalam kontak telepon seluler Anda agar dapat menggunakan fitur pesan Kami dan untuk tujuan lain yang relevan.",

          "e. Rekaman dalam kendaraan",
          "1. Kami tidak mewajibkan, mendukung, atau melarang pemasangan atau penggunaan perangkat perekam di kendaraan manapun. Jika perangkat tersebut dipasang dan digunakan, Kami tidak mengumpulkan Data Pribadi apapun dari rekaman dalam kendaraan tersebut, dan pengumpulan Data Pribadi melalui perangkat tersebut tidak dilakukan atas nama Kami. Pengumpulan Data Pribadi dari rekaman dalam kendaraan tersebut sepenuhnya menjadi tanggung jawab penyedia layanan. Kami tidak memiliki kontrol atas pengumpulan Data Pribadi oleh penyedia layanan atau bagaimana data tersebut digunakan atau diungkapkan. Jika Anda adalah penyedia layanan dan memutuskan untuk menginstal perangkat perekam dalam kendaraan Anda, Anda bertanggung jawab penuh untuk memberi tahu pengguna. Jika Anda adalah pengguna dan merasa keberatan dengan penggunaan perangkat perekam oleh penyedia layanan, Anda harus menyampaikan keberatan tersebut langsung kepada penyedia layanan, dan hal ini menjadi tanggung jawab pribadi Anda."
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>4. BAGAIMANA KAMI MENGGUNAKAN DATA PRIBADI ANDA</Title>
      <Paragraph style={{ textAlign: 'left' }}>
        Kami dapat menggunakan Data Pribadi yang dikumpulkan untuk tujuan-tujuan berikut, serta tujuan lain yang diizinkan oleh Peraturan Perundang-undangan yang Berlaku ("Tujuan"):
      </Paragraph>

      <List
        style={{textAlign: 'justify'}}
        dataSource={[
          "a. Jika Anda adalah pengguna, Kami dapat menggunakan Data Pribadi Anda untuk:",
          "1. Untuk mengidentifikasi dan mendaftarkan Anda sebagai pengguna, serta untuk membuat, memverifikasi, menonaktifkan, atau mengelola akun Anda di Aplikasi Kami atau aplikasi lain dalam Grup Desapedia.",
          "2. Untuk memfasilitasi atau memungkinkan verifikasi yang dianggap perlu oleh Kami sebelum penyedia layanan memberikan layanan kepada Anda, atau sebelum Kami mendaftarkan Anda sebagai pengguna, termasuk melakukan proses Know Your Customer (KYC) dan penilaian kredit (credit scoring) jika diperlukan.",
          "3. Untuk memungkinkan Kami menyediakan layanan, baik yang sudah tersedia, yang Anda minta, maupun yang akan tersedia di masa mendatang.",
          "4. Untuk memproses dan memfasilitasi pesanan serta transaksi pembayaran yang Anda lakukan, termasuk transaksi yang dilakukan melalui kartu pembayaran, uang elektronik, dompet elektronik, atau rekening yang tersedia di Aplikasi.",
          "5. Untuk memberi tahu Anda mengenai transaksi atau aktivitas yang terjadi dalam Aplikasi atau sistem lain yang terhubung dengan Aplikasi Kami.",
          "6. Untuk memfasilitasi penghubungan akun dan/atau dompet, jika Anda mengaktifkan penghubungan akun dan/atau dompet digital seperti Fello, Dana, dan dompet digital lainnya yang bekerja sama dengan Kami, dengan Aplikasi dalam Grup Desapedia atau dengan platform pihak ketiga, sesuai dengan ketentuan yang berlaku.",
          "7. Untuk berkomunikasi dengan Anda dan mengirimkan informasi terkait penggunaan Aplikasi.",
          "8. Untuk memberi tahu Anda mengenai pembaruan pada Aplikasi atau perubahan pada layanan yang disediakan.",
          "9. Untuk mengolah, memproses, menyelesaikan, dan merespons keluhan, masalah, pertanyaan, atau saran yang Anda ajukan.",
          "10. Untuk melakukan pemeliharaan, pengembangan, pengujian, peningkatan, dan personalisasi Aplikasi agar sesuai dengan kebutuhan dan preferensi Anda sebagai pengguna.",
          "11. Untuk memantau dan menganalisis aktivitas, perilaku, serta data demografis Anda, termasuk kebiasaan dan penggunaan berbagai layanan yang tersedia di Aplikasi.",
          "12. Untuk memproses dan mengelola poin reward yang Anda miliki.",
          "13. Untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra Kami.",
          "14. Untuk mengirimkan komunikasi pemasaran, iklan, voucher, survei, serta informasi langsung atau tertarget, serta informasi terkait penawaran atau promosi khusus, dan mengenai acara, promosi, fitur, atau peluncuran yang sedang berlangsung.",

          "b. Jika Anda adalah penyedia layanan, Kami dapat menggunakan Data Pribadi Anda:",
          "1. Untuk mengidentifikasi dan mendaftarkan Anda sebagai penyedia layanan serta membuat, mengelola, atau memverifikasi akun Anda di Aplikasi Kami atau aplikasi Grup GoTo lainnya.",
          "2. Untuk memfasilitasi atau memungkinkan verifikasi yang dianggap perlu sebelum Kami mendaftarkan Anda sebagai penyedia layanan, termasuk untuk proses Know Your Customer (KYC) dan penilaian kredit (credit scoring) jika diperlukan.",
          "3. Untuk memungkinkan Anda dalam menyediakan layanan kepada pengguna.",
          "4. Untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran kepada Anda terkait layanan yang telah Anda berikan.",
          "5. Untuk berkomunikasi dengan Anda dan mengirimkan informasi terkait penyediaan layanan Anda, termasuk untuk menyampaikan pesanan pengguna kepada Anda dan memfasilitasi penerimaan pesanan tersebut.",
          "6. Untuk memberikan pemberitahuan dan pembaruan terkait Aplikasi atau perubahan dalam cara penyediaan layanan.",
          "7. Untuk memberikan laporan terkait layanan yang telah Anda sediakan.",
          "8. Untuk memproses dan merespons masukan atau saran dari pengguna mengenai layanan yang telah Anda berikan.",
          "9. Untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasi Aplikasi agar sesuai dengan kebutuhan dan preferensi Anda sebagai penyedia layanan.",
          "10. Untuk memantau dan menganalisis aktivitas, perilaku, serta data demografis Anda, termasuk kebiasaan dan respons Anda terhadap berbagai layanan yang tersedia di Aplikasi.",
          "11. Untuk mengelola dan memproses reward points Anda (apabila berlaku).",
          "12. Untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra Kami.",
          "13. Untuk mengirimkan komunikasi pemasaran, iklan, promosi, survei, serta penawaran atau promosi khusus secara langsung atau ditargetkan, termasuk informasi tentang acara, promosi, fitur, atau peluncuran yang sedang berlangsung.",

          "c. Kami juga dapat menggunakan Data Pribadi yang Anda berikan secara lebih umum untuk tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu Kami akan bertindak secara wajar dan tidak menggunakan Data Pribadi Anda lebih dari apa yang diperlukan untuk tujuan tersebut):",
          "1. Untuk melaksanakan proses dan fungsi bisnis yang relevan.",
          "2. Untuk memantau penggunaan Aplikasi dan mengelola, mendukung, serta meningkatkan kinerja, perkembangan, pengalaman Pengguna, dan fungsi-fungsi Aplikasi.",
          "3. Untuk memberikan bantuan terkait dan menyelesaikan masalah teknis atau operasional yang terjadi dengan Aplikasi atau layanan.",
          "4. Untuk menghasilkan informasi statistik dan data analitik guna pengujian, penelitian, analisis, pengembangan produk, kemitraan komersial, dan kolaborasi.",
          "5. Untuk mencegah, mendeteksi, dan menyelidiki transaksi yang mencurigakan atau kegiatan yang melanggar hukum, ilegal, tidak sah, mencurigakan, bermaksud jahat, atau penipuan.",
          "6. Untuk memfasilitasi transaksi aset bisnis (termasuk penggabungan, akuisisi, atau penjualan aset) yang melibatkan Kami dan/atau afiliasi Kami.",
          "7. Untuk memungkinkan Kami memenuhi semua kewajiban yang ditetapkan oleh Peraturan Perundang-undangan yang Berlaku, termasuk namun tidak terbatas pada merespons permintaan, penyelidikan, atau keputusan yang sesuai dengan peraturan, mematuhi persyaratan pengarsipan, pelaporan, dan perizinan yang diharuskan oleh hukum atau peraturan yang berlaku, serta melakukan audit, uji tuntas, dan penyelidikan.",
          "8. Untuk tujuan lain yang Kami informasikan kepada Anda, dan jika diperlukan oleh Peraturan Perundang-undangan yang Berlaku, setelah Kami memperoleh persetujuan Anda."
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>5. PENGUNGKAPAN DATA PRIBADI ANDA </Title>
      <Paragraph style={{ textAlign: 'left' }}>
      Kami dapat mengungkapkan, memberikan akses, atau membagikan Data Pribadi Anda dengan afiliasi dan pihak lain untuk tujuan berikut ini, serta untuk tujuan lain yang diizinkan oleh Peraturan Perundang-undangan yang Berlaku:
      </Paragraph>
      <List
        style={{textAlign: 'justify'}}
        dataSource={[
          "1. Untuk memungkinkan penyedia layanan melakukan atau memberikan layanan kepada Anda, termasuk menghubungi Anda terkait dengan pemrosesan pesanan yang Anda buat sebagai pengguna.",
          "2. Untuk memungkinkan pengguna meminta atau menerima layanan dari Anda sebagai penyedia layanan, termasuk menghubungi Anda dan menyelenggarakan promosi, kontes, atau layanan khusus yang tersedia dalam Aplikasi.",
          "3. Menanggapi pertanyaan terkait regulasi, permintaan, atau penyelidikan yang mematuhi persyaratan pengarsipan, pelaporan, atau keputusan hukum, sesuai dengan Peraturan Perundang-undangan yang Berlaku.",
          "4. Jika diinstruksikan oleh pemerintah atau sesuai dengan peraturan yang berlaku, untuk tujuan yang diizinkan oleh hukum atau Peraturan Perundang-undangan yang Berlaku.",
          "5. Jika ada proses hukum, baik yang melibatkan Anda atau pihak lain, terkait dengan layanan kami, untuk keperluan proses hukum tersebut.",
          "6. Untuk tujuan verifikasi, baik oleh Kami atau pihak ketiga yang terlibat, termasuk proses Know Your Customer (KYC) dan penilaian kredit sebelum penyedia layanan menyediakan layanan kepada Anda.",
          "7. Jika layanan Kami tersedia di platform pihak ketiga, untuk membantu Kami memperoleh Data Pribadi Anda atau mendaftarkan Anda pada platform pihak ketiga tersebut.",
          "8. Dalam keadaan darurat terkait kesehatan atau keselamatan Anda, untuk membantu menangani keadaan darurat tersebut.",
          "9. Terkait dengan kesehatan atau kepentingan umum, Kami dapat membagikan Data Pribadi Anda kepada institusi pemerintah atau lembaga lain yang berwenang untuk mendukung inisiatif kesehatan publik atau kebijakan pemerintah.",
          "10. Sehubungan dengan penggabungan, penjualan, atau restrukturisasi perusahaan Kami, untuk keperluan transaksi bisnis tersebut.",
          "11. Untuk proses klaim asuransi, Kami akan membagikan Data Pribadi Anda kepada perusahaan asuransi yang bekerja sama dengan Kami.",
          "12. Kepada pihak ketiga yang menyediakan layanan atau melakukan tugas atas nama Kami untuk melaksanakan transaksi Anda, yang dapat mencakup pemasaran, penelitian, analisis, dan pengembangan produk.",
          "13. Dengan afiliasi atau pihak ketiga lainnya yang membantu Kami dalam menyediakan Aplikasi dan operasi bisnis, untuk tujuan pemasaran langsung, penilaian risiko, atau pengolahan data atas nama Kami."
        ]}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami berkomitmen untuk melindungi privasi Anda. Oleh karena itu, ketika Data Pribadi Anda tidak perlu dikaitkan dengan identitas Anda, kami akan melakukan upaya yang wajar untuk memastikan bahwa Data Pribadi tersebut tidak dapat dikaitkan dengan Anda sebagai individu sebelum mengungkapkan atau membagikan data tersebut.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami juga ingin menegaskan bahwa Kami tidak akan menjual atau menyewakan Data Pribadi Anda kepada pihak manapun.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Selain yang telah dijelaskan dalam Pemberitahuan Privasi ini, Kami hanya akan mengungkapkan dan membagikan Data Pribadi Anda jika Kami memberikan pemberitahuan kepada Anda atau jika Kami telah memperoleh persetujuan Anda untuk pengungkapan atau pembagian data tersebut. Semua pengungkapan atau pembagian data tersebut akan dilakukan sesuai dengan peraturan yang berlaku dan dengan mempertimbangkan hak privasi Anda.
      </Paragraph>
      
      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>6. RETENSI DATA </Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami akan menyimpan Data Pribadi Anda hanya selama diperlukan untuk memenuhi Tujuan pengumpulannya dan/atau selama penyimpanan tersebut diizinkan atau diperlukan sesuai dengan Peraturan Perundang-undangan yang Berlaku. Setelah itu, Kami akan menghapus atau menganonimkan Data Pribadi Anda segera setelah tidak lagi diperlukan untuk tujuan tersebut.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Perlu dicatat bahwa dalam beberapa kasus, Data Pribadi Anda mungkin masih disimpan atau dikuasai oleh pihak lain, seperti institusi pemerintah atau pihak yang bekerja sama dengan Kami. Jika Kami membagikan Data Pribadi Anda kepada institusi tersebut, Anda mengakui dan menyetujui bahwa penyimpanan Data Pribadi Anda oleh institusi terkait akan mengikuti kebijakan penyimpanan data masing-masing institusi tersebut.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Selain itu, apabila ada komunikasi antara pengguna dan penyedia layanan yang dilakukan melalui saluran selain Aplikasi (misalnya, melalui telepon, SMS, atau pesan seluler), data yang disampaikan melalui saluran ini mungkin disimpan dengan cara tertentu. Kami ingin menegaskan bahwa Kami tidak mengizinkan Pemrosesan Data Pribadi di luar Aplikasi Kami. Oleh karena itu, pengguna dan penyedia layanan bertanggung jawab penuh atas Pemrosesan Data Pribadi yang terjadi di luar Aplikasi Kami.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Dalam batas yang diizinkan oleh hukum, Anda juga membebaskan Kami dari setiap klaim atau kerugian yang mungkin timbul akibat aktivitas yang dilakukan di luar Aplikasi Kami, termasuk biaya hukum atau biaya penggantian kerugian yang mungkin terjadi.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>7. DATA ANONIM</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami sangat menghargai kerahasiaan Data Pribadi Anda dan akan berusaha sebaik mungkin untuk melindungi dan mengamankan data tersebut dari akses, pengumpulan, penggunaan, atau pengungkapan oleh pihak yang tidak berwenang, serta dari pengolahan yang melanggar hukum, kehilangan tidak sengaja, pemusnahan, atau kerusakan. Namun, karena pengiriman data melalui 
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      internet tidak sepenuhnya aman, kami tidak dapat memberikan jaminan penuh bahwa Data Pribadi Anda tidak akan dapat diakses, diungkapkan, diubah, atau dihancurkan oleh pihak ketiga yang tidak sah, terutama faktor-faktor yang berada di luar kendali kami. Anda bertanggung jawab untuk menjaga kerahasiaan akun Anda, tidak membagikan informasi akun seperti kata sandi atau OTP dengan pihak lain, dan selalu memastikan perangkat yang Anda gunakan tetap aman.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>8. DATA ANONIM</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami berhak untuk membuat, menggunakan, memberikan lisensi, atau membagikan Data Pribadi yang tersedia, dengan ketentuan bahwa: (i) semua elemen yang dapat mengidentifikasi individu telah dihapus, sehingga data tersebut, baik secara terpisah maupun digabungkan dengan data lainnya, tidak dapat dihubungkan atau dikaitkan dengan individu tertentu, dan (ii) data tersebut telah digabungkan dengan data serupa sehingga membentuk bagian dari kumpulan data yang lebih besar.
      </Paragraph>
      
      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>9. HAK HUKUM ANDA</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Anda mungkin memiliki hak tertentu sesuai dengan Peraturan Perundang-undangan yang Berlaku untuk meminta akses, perbaikan, dan/atau penghapusan Data Pribadi yang Kami simpan dan kontrol. Anda dapat menggunakan hak-hak ini dengan menghubungi Kami melalui informasi kontak yang tertera dalam Pemberitahuan Privasi ini.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami berhak untuk menolak permintaan Anda terkait akses, perbaikan, dan/atau penghapusan sebagian atau seluruh Data Pribadi yang Kami kendalikan, jika permintaan tersebut diwajibkan atau diperlukan oleh Peraturan Perundang-undangan yang Berlaku. Penolakan ini termasuk jika Data Pribadi yang diminta berisi informasi yang mengacu pada pihak lain atau jika permintaan dianggap tidak relevan, tidak serius, atau berpotensi melanggar ketentuan penggunaan atau terkait dengan aktivitas ilegal.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Sesuai dengan ketentuan Peraturan Perundang-undangan yang Berlaku, Kami dapat membebankan biaya administratif atas setiap permintaan untuk akses dan/atau perbaikan data.
      </Paragraph>

      {/* Link to Formulir Kebijakan Privasi */}
      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>10. CARA MENGAJUKAN PERMINTAAN PENGHAPUSAN AKUN ANDA</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Anda dapat menggunakan hak Anda untuk menghapus akun Anda melalui Aplikasi Kami. Jika Anda mengalami kesulitan dalam proses ini, seperti jika Anda tidak lagi memiliki akses ke Aplikasi, Anda dapat mengajukan permintaan untuk penghapusan akun Anda:
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Jika akun Anda terdaftar dengan layanan Kami di Indonesia, Anda dapat mengajukan permintaan melalui situs
      <span>&nbsp;</span>
      <Link className='footer-link' to='/privacy' target='_blank' rel='noopener noreferrer'>
       web yang disediakan berikut ini;
      </Link>
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami berhak menolak permintaan Anda dengan alasan yang sah menurut Peraturan Perundang-undangan yang Berlaku. Penolakan ini termasuk jika kami menilai permintaan tersebut tidak relevan, tidak serius, atau tidak beralasan, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan ilegal.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>11. SITUS WEB DAN LAYANAN LAIN</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Aplikasi dan Materi Pemasaran (seperti yang dijelaskan di bawah) mungkin mencakup tautan ke situs web yang dikelola oleh pihak ketiga. Segala pemrosesan Data Pribadi di situs web tersebut sepenuhnya diatur oleh pihak ketiga dan menjadi tanggung jawab mereka. Jika Anda memilih untuk mengunjungi situs web pihak ketiga tersebut, Anda akan tunduk pada syarat dan ketentuan serta kebijakan privasi mereka. Oleh karena itu, kami menyarankan Anda untuk membaca syarat dan ketentuan serta kebijakan privasi situs web pihak ketiga guna memahami cara mereka mengumpulkan dan memproses Data Pribadi Anda.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Saat Anda menggunakan Aplikasi Kami dan mengaktifkan fitur sidik jari (fingerprint) dan/atau pengenalan wajah (facial recognition) pada perangkat seluler Anda untuk autentikasi, harap diperhatikan bahwa Kami tidak menyimpan data biometrik tersebut. Kecuali diinformasikan sebaliknya, data tersebut disimpan pada perangkat seluler Anda dan dapat disimpan oleh pihak ketiga, seperti produsen perangkat seluler Anda. Anda memahami dan menyetujui bahwa Kami tidak bertanggung jawab atas akses tidak sah atau kerugian yang terkait dengan data biometrik yang disimpan di perangkat seluler Anda.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Iklan yang terdapat dalam Aplikasi atau Materi Pemasaran Kami dapat mengarahkan Anda ke situs web pengiklan, dan data yang dikumpulkan berdasarkan interaksi Anda dengan tautan tersebut akan dikumpulkan dan digunakan oleh pengiklan sesuai dengan kebijakan privasi mereka.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>12. MATERI PEMASARAN DAN PROMOSI </Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami dan mitra kami (termasuk vendor, pemasok, pedagang, dan/atau pihak ketiga lainnya yang bekerja sama dengan kami untuk tujuan komersial maupun non-komersial) dapat mengirimkan pemasaran langsung, iklan, serta komunikasi promosi melalui berbagai saluran seperti push-notification Aplikasi, pesan dalam Aplikasi, surat pos, platform chat, media sosial, dan email (“Materi Pemasaran”), jika Anda telah memberikan persetujuan untuk berlangganan milis kami atau setuju untuk menerima materi pemasaran dari kami. Anda dapat memilih untuk berhenti menerima Materi Pemasaran tersebut kapan saja dengan mengklik opsi “berhenti berlangganan” yang tersedia dalam pesan terkait, menggunakan metode lain yang tersedia pada saluran promosi tersebut, atau menghubungi kami melalui informasi kontak yang tercantum dalam Pemberitahuan Privasi ini.
      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
      Perlu dicatat bahwa meskipun Anda memilih untuk berhenti berlangganan, kami masih dapat mengirimkan pesan non-promosi kepada Anda, seperti tanda terima atau informasi terkait akun Anda.
      </Paragraph>
      
      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>13. PEMBAHARUAN TERHADAP PEMBERITAHUAN PRIVASI</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Kami berhak untuk meninjau dan memperbarui Pemberitahuan Privasi ini sesuai dengan kebijakan kami, seiring dengan perkembangan yang kami lakukan di masa depan, serta perubahan dalam persyaratan hukum atau regulasi yang berlaku. Jika kami memutuskan untuk melakukan perubahan pada Pemberitahuan Privasi ini, kami akan memberi tahu Anda mengenai perubahan tersebut melalui saluran resmi kami atau melalui email yang tercantum pada akun Anda. 
      Anda setuju bahwa adalah tanggung jawab Anda untuk secara berkala memeriksa Pemberitahuan Privasi ini untuk memperoleh informasi terbaru terkait pengolahan data dan kebijakan perlindungan data kami. 
      Dengan terus menggunakan Aplikasi kami, berinteraksi dengan kami, 
      atau mengakses dan menggunakan layanan setelah adanya perubahan pada Pemberitahuan Privasi ini, 
      Anda dianggap menyetujui Pemberitahuan Privasi beserta perubahan-perubahannya.
      </Paragraph>

      <Title level={2} style={{ fontSize: '18px', textDecoration: 'underline' }}>14. CARA MENGHUBUNGI KAMI</Title>
      <Paragraph style={{ textAlign: 'justify' }}>
      Apabila Anda memiliki pertanyaan atau keluhan terkait Pemberitahuan Privasi ini, atau jika Anda ingin mengakses hak-hak Anda terkait Data Pribadi Anda, silakan hubungi Kantor Perlindungan Data dan Privasi yang sesuai:      </Paragraph>
      <Paragraph style={{ textAlign: 'justify' }}>
        PT Agriva Teknologi Nusantara (Data Protection & Privacy Office)<br />
        jl. Durian I No. 30 Blok D3 - Cluster Durian - Vida Bumi Pala, Padurenan, Kec. Mustika Jaya, Kota Bekasi, Jawa Barat 17156.
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
