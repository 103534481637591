import React from "react";
import "./style.css";

const DeleteAccountSuccess = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="currentColor"
            className="bi bi-check-circle-fill"
            style={{ color: "green" }}
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        </div>
        <div>
          <div>
            <h1>SUKSES</h1>
          </div>
          <div>
            <p>Selamat akun berhasil dihapus</p>
          </div>
          <button
            onClick={() => (window.location.href = "/")}
            className="button-kembali"
          >
            Kembali
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountSuccess;
