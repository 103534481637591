import React, { useState } from 'react';
import './style.css';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { config } from '../../config';
import axios from 'axios';
import LoadingOverlay from '../../component/Loading';
import DesapediaLogo from '../../assets/images/Desapedia-01.png';

const DeleteAccount = () => {
  const [responData, setResponData] = useState({});
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({
    username: '',
    password: '',
  });

  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleError = () => setModalError(!modalError);
  const toggleSuccess = () => setModalSuccess(!modalSuccess);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    toggle();
  };

  const onSubmit = async () => {
    toggle();
    setLoading(true);
    try {
      const dataToSend = {
        ...form,
      };

      const headers = {
        'Content-Type': 'application/json',
        apikey: config.API_KEY,
      };

      const response = await axios.post(
        `${config.BASE_URL_DRIVER}auth/deleteAccount`,
        dataToSend,
        { headers }
      );

      if (response.status === 200) {
        setLoading(false);
        toggleSuccess();
        setResponData(response.data);
      }
    } catch (error) {
      setModalError(true);
      setLoading(false);
      setResponData(error.response.data);
    }
  };

  return (
    <div className='separators'>
      <div className='containers'>
        {loading && <LoadingOverlay />}
        
        <Modal isOpen={modalSuccess} toggle={toggleSuccess} centered>
          <ModalBody>
            <Alert>
              <h4 className='alert-heading'>Hapus Akun Sukses!</h4>
              <p>{responData.message}</p>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={() => {
                setModalSuccess(false);
                window.location.href = '/';
              }}
            >
              Tutup
            </Button>
          </ModalFooter>
        </Modal>
        
        <Modal isOpen={modalError} toggle={toggleError} centered>
          <ModalBody>
            <p>{responData.message}</p>
          </ModalBody>
          <ModalFooter style={{ border: 'none' }}>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={() => setModalError(false)}
            >
              Tutup
            </Button>
          </ModalFooter>
        </Modal>
        
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalBody>Apakah Anda yakin ingin menghapus akun ini?</ModalBody>
          <ModalFooter style={{ border: 'none' }}>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={onSubmit}
            >
              Iya
            </Button>
            <Button style={{ background: 'gray' }} size='sm' onClick={toggle}>
              Batal
            </Button>
          </ModalFooter>
        </Modal>

        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <div style={{ marginBottom: 40 }}>
            <img width={200} src={DesapediaLogo} alt='logo' />
          </div>
          <h5 style={{ marginBottom: '40px' }}>Permintaan Hapus Akun</h5>
          <p>Untuk melakukan permintaan hapus akun, silahkan anda mengisi detail akun anda</p>
        </div>
        <form className='form' onSubmit={handleConfirm}>
          <div className='form-username'>
            <div>
              <label htmlFor='username'>Username</label>
            </div>
            <div>
              <input
                name='username'
                type='text'
                id='username'
                value={form.username}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='form-password'>
            <div>
              <label htmlFor='password'>Password</label>
            </div>
            <div>
              <input
                name='password'
                id='password'
                type='password'
                value={form.password}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <p className='warning-text'>
            *Perhatian! Akun yang telah dihapus tidak dapat di kembalikan kembali
          </p>
          <input
            className='form-submit'
            type='submit'
            value='SUBMIT'
            disabled={!form.username || !form.password}
          />
        </form>
      </div>
    </div>
  );
};

export default DeleteAccount;
