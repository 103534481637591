import Layout1 from '../src/pages/Layout1/Layout1';
import Article from '../src/pages/Article';
import Contact from '../src/pages/Contact';
import Register from '../src/pages/Register';
import Policy from '../src/pages/Policy';
import Fitur from '../src/pages/Fitur/Fitur';
import DetailArtikel from './pages/Artikel/DetailArtikel';
import GaspolPolicy from './pages/GaspolPolicy';
import DeleteAccount from './pages/DeleteAccount';
import DeleteAccountSuccess from './pages/DeleteAccountSuccess';
import DeleteAccountConfirm from './pages/DeleteAccountConfirm';
import PrivacyWebForm from './component/NotePrivacy/Privacy/Index';
import GaspolDeleteAccount from './pages/GaspolDeleteAccount';
// import Layout2 from "../src/pages/Layout2/Layout2";
// import Layout3 from "../src/pages/Layout3/Layout3";
// import Layout4 from "../src/pages/Layout4/Layout4";
// import Layout5 from "../src/pages/Layout5/Layout5";

const routes = [
  // { path: '/delete-account-confirm', component: DeleteAccountSuccess },
  {
    path: '/delete-account-confirm/:username',
    component: DeleteAccountConfirm,
  },
  { path: '/delete-account-success', component: DeleteAccountSuccess },
  { path: '/delete-account', component: DeleteAccount },
  { path: '/gaspol-delete-account', component: GaspolDeleteAccount },
  { path: '/gaspol-privacy-policy', component: GaspolPolicy },
  { path: '/privacy-policy-gaspol', component: GaspolPolicy }, //clone rute, untuk testing reject playstore
  { path: '/mobileapp-privacy-policy', component: Policy },
  { path: '/artikel/:title/:artikelId', component: DetailArtikel },
  { path: '/artikel', component: Article },
  { path: '/kontak', component: Contact },
  { path: '/privacy', component: PrivacyWebForm },
  { path: '/register', component: Register },
  { path: '/fitur', component: Fitur },
  { path: '/', component: Layout1 },

  // { path: "/Layout2", component: Layout2 },
  // { path: "/Layout3", component: Layout3 },
  // { path: "/Layout4", component: Layout4 },
  // { path: "/Layout5", component: Layout5 },
];

export default routes;
